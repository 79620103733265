body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.forms-section {
  position: relative;
  z-index: 1;
  background-image: url(/static/media/full-width-image.0848d9d0.jpg);
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}

.forms-section:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

.forms-section-wrapper {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 0 50px;
  box-sizing: border-box;
}

.forms-section-wrapper:last-child {
  padding: 0;
}

.forms-section-heading h2 {
  font-family: 'Playball', cursive;
  font-weight: normal;
  font-size: 50px;
  margin: 0;
  overflow: auto;
}

.forms-section-text {
  line-height: 1.5em;
  font-size: 18px;
}

.forms-section-button {
  color: #fff;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  background: #890f3a;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  outline: 0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.forms-section-button:hover {
  background: #440018;
  letter-spacing: 1px;
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.forms-section-modal {
  width: 100%;
  height: 100%;
  padding: 50px 10px 10px;
  box-sizing: border-box;
}

.forms-section-modal-overlay {
  padding: 0;
}

.forms-section-modal-iframe-container {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.forms-section-modal-iframe {
  max-width: 100%;
  height: 99%;
}

.forms-section-modal-close {
  cursor: pointer;
  outline: 0;
}

@media (min-width: 768px) {
  .forms-section-modal {
    border-radius: 5px;
    padding: 1.2rem;
    padding-right: 50px;
    width: auto;
    max-width: 800px;
  }

  .forms-section-modal-overlay {
    padding: 1.2rem;
  }
}

@media (min-width: 992px) {
  .forms-section {
    padding: 70px 20px;
  }

  .forms-section-text {
    font-size: 23px;
  }

  .forms-section-button {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .forms-section-wrapper {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    padding: 0 50px !important;
  }
}

@media (min-width: 1440px) {
  .forms-section-wrapper {
    padding: 0 100px !important;
  }
}

.countdown-col {
  display: inline-block;
}

.countdown-col-element {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 12px;
  font-size: 12px;
  font-family: 'Raleway';
}

.countdown-col-element-number {
  font-size: 25px;
  font-family: 'Lato';
}

@media (min-width: 992px) {
  .countdown-col-element {
    margin: 0 20px;
    font-size: 16px;
  }

  .countdown-col-element-number {
    font-size: 40px;
  }
}

.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #890f3a;
}

.field input {
  width: 100%;
  height: 56px;
  position: relative;
  box-sizing: border-box;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lato';
  line-height: normal;
  background-color: transparent;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  -webkit-transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Lato';
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}

.main-slider {
  height: 100vh;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-color: #333333;
  background-position: center;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.main-slider:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.3);
}

.main-slider > figure {
  -webkit-animation-name: imageAnimation;
          animation-name: imageAnimation;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-size: cover;
  background-position: center;
  background-color: #333333;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: -1;
}

@-webkit-keyframes imageAnimation {
  0% {
    opacity: 1;
  }
  3% {
    opacity: 1;
  }
  6% {
    opacity: 0;
  }
  86.33% {
    opacity: 0;
  }
  89.33% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 1;
  }
  3% {
    opacity: 1;
  }
  6% {
    opacity: 0;
  }
  86.33% {
    opacity: 0;
  }
  89.33% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.slider-content {
  padding: 0 20px;
}

.slider-content .pre-title {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 40px;
  margin: 15px 0;
}

.slider-content i {
  line-height: 1;
}

.slider-content i.small-icon {
  font-size: 2.5em;
}

.slider-content .title {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 62px;
  margin: 15px 0;
}

.slider-content .title i {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  font-size: 0.8em;
  color: #890f3a;
}

.slider-content .date {
  font-size: 20px;
  margin: 15px 0;
}

.count-down {
  margin-top: 35px;
}

.slider-scroll i {
  margin-top: 35px;
  color: #fff;
  position: relative;
  font-size: 3rem;
  z-index: 3;
  top: 3rem;
  cursor: pointer;
}

.fade-down {
  animation-name: fade-down;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade-down;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes fade-down {
  0% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translatey(20px);
    transform: translatey(20px);
    opacity: 0;
  }
}

@keyframes fade-down {
  0% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translatey(20px);
    transform: translatey(20px);
    opacity: 0;
  }
}

.fade-enter {
  opacity: 0.01;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.fade-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 700ms;
  transition: all 700ms;
}

.fade-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.fade-exit-active {
  opacity: 0.01;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 700ms;
  transition: all 700ms;
}

@media (min-width: 992px) {
  .slider-content .pre-title {
    font-size: 50px;
  }

  .slider-content .title {
    font-size: 85px;
    margin: 15px 60px 15px 0;
  }

  .slider-content .date {
    font-size: 25px;
  }
}

.image-section {
  position: relative;
  z-index: 1;
  background-image: url(/static/media/full-width-image.0848d9d0.jpg);
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  box-sizing: border-box;
  margin-top: 30px;
}

.image-section:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

.image-section-text-wrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}

.image-section-heading h2 {
  font-family: 'Playball', cursive;
  font-weight: normal;
  font-size: 50px;
  margin: 0;
  overflow: auto;
}

.image-section-text {
  line-height: 1.5em;
  font-size: 18px;
}

.image-section-text a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

.image-section-text a:hover {
  text-decoration: underline;
}

.image-section-child-wrapper {
  width: 100%;
  max-width: 287px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0 auto;
}

@media (min-width: 992px) {
  .image-section {
    padding: 70px 20px;
  }

  .image-section-text {
    font-size: 23px;
  }

  .image-section-child-wrapper {
    max-width: 850px;
  }
}

.instagram-wall-wrapper {
  width: 100%;
  max-width: 287px;
  margin: 0 auto;
  overflow: hidden;
}

.instagram-wall-iframe {
  border: 0;
  height: 267px;
  width: 1400px;
  margin-left: 7px;
}

@media (min-width: 992px) {
  .instagram-wall-wrapper {
    max-width: 850px;
  }
}

.map-location-wrapper {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
}

.map-location-title h2 {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 46px;
  margin: 0;
  padding: 0 15px;
  display: inline;
  color: #000000;
  border-bottom: 3px solid;
  border-color: transparent;
}

.map-location-title i {
  margin-right: 15px;
  color: #890f3a;
}

.map-location-wrapper-hovered .map-location-title h2 {
  border-color: #890f3a;
}

@media (min-width: 768px) {
  .map-location-wrapper {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    padding: 30px 20px;
    box-sizing: border-box;
  }

  .map-location-title h2 {
    font-size: 56px;
  }
}

.map-section {
  min-height: 450px;
  display: -webkit-flex;
  display: flex;
  box-shadow: 1px 10px 40px rgba(0, 0, 0, 0.4);
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 30px;
}

.map-section-wrapper {
  min-height: 450px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
}

@media (min-width: 992px) {
  .map-section-wrapper {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.map-section a {
  color: #890f3a;
  text-decoration: none;
}

.map-section a:hover {
  text-decoration: underline;
}
.section {
  margin: 20px;
  box-sizing: border-box;
}

.section-text-wrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}

.section-heading {
  padding-top: 30px;
  padding-bottom: 15px;
}

.section-heading h2 {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 56px;
  margin: 0;
}

.section-separator {
  padding-bottom: 15px;
}

.section-heading:after,
.section-separator:after {
  content: '';
  background: url(/static/media/separator.df6615eb.png) no-repeat center center;
  height: 50px;
  display: block;
}

.section-text {
  line-height: 1.5em;
  font-size: 16px;
}

.section-text ul {
  display: inline-block;
  text-align: left;
  margin: 0;
}

.section-text h2 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
}

.section-text a {
  color: #890f3a;
  text-decoration: none;
}

.section-text a:hover {
  text-decoration: underline;
}

.modal-button {
  color: #fff;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  background: #890f3a;
  padding: 16px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  outline: 0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.modal-button:hover {
  background: #440018;
  letter-spacing: 1px;
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.modal-component {
  padding: 0 0 1.2rem;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.modal-overlay {
  padding: 0;
}

.modal-close {
  cursor: pointer;
  outline: 0;
}

@media (min-width: 768px) {
  .modal-component {
    border-radius: 5px;
    width: auto;
    max-width: 800px;
    min-height: 0;
  }

  .modal-overlay {
    padding: 1.2rem;
  }
}

.nav {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 40px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: background_white 0.75s;
          animation: background_white 0.75s;
}

.nav-transparent {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  -webkit-animation: background_transparent 0.75s;
          animation: background_transparent 0.75s;
}

@media (min-width: 992px) {
  .nav {
    height: 80px;
  }

  .nav-transparent {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@-webkit-keyframes background_transparent {
  from {
    background-color: rgba(255, 255, 255, 0.5);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes background_transparent {
  from {
    background-color: rgba(255, 255, 255, 0.5);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes background_white {
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: #fff;
  }
}

@keyframes background_white {
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: #fff;
  }
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.nav-mobile-menu-button {
  position: absolute;
  z-index: 1000;
}

.nav .nav-icon {
  margin: 0 auto;
  color: #890f3a;
  height: 100%;
  width: 100%;
  position: absolute;
  font-size: 28px;
  padding: 5px 0;
}

.nav-transparent .nav-icon {
  display: none;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

.nav-items {
  padding-left: 0;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}

.nav-item:first-child {
  margin-left: 0 !important;
}

.nav-transparent .nav-item {
  display: inline;
  margin-left: 2rem;
  color: #fff;
}

.nav-item > .active {
  border-bottom: 1px solid #890f3a;
}

.nav-item:hover {
  color: #000;
  border-bottom: 1px solid #890f3a;
  cursor: pointer;
}

.nav-transparent .nav-item:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.timeline-item {
  padding-bottom: 20px;
  position: relative;
  font-size: 16px;
}

.timeline-image {
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
}

.timeline-title {
  font-size: 25px;
  font-weight: 300;
  margin: 10px 0;
}

.timeline-arrow {
  display: none;
}

.timeline-left-side {
  padding-right: 10px;
  text-align: right;
}

.date-circle:after {
  background: none repeat scroll 0 0 #890f3a;
  border: 3px solid #ececec;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 7px;
  width: 15px;
  height: 15px;
  z-index: 20;
}

.timeline-left-side.date-circle:after {
  right: -20.5px;
}

@media (min-width: 992px) {
  .timeline-image {
    max-width: 400px;
  }

  .timeline-arrow {
    display: block;
    position: absolute;
    top: 18px;
  }

  .timeline-arrow:after,
  .timeline-arrow:before {
    border: medium solid transparent;
    content: ' ';
    position: absolute;
  }

  .timeline-left-side {
    padding-right: 70px;
    text-align: right;
  }

  .timeline-left-side .timeline-arrow {
    right: 33px;
  }

  .timeline-left-side .timeline-arrow:after,
  .timeline-left-side .timeline-arrow:before {
    right: 100%;
  }

  .timeline-left-side .timeline-arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 6px;
    margin-top: -6px;
  }

  .timeline-left-side .timeline-arrow:before {
    border-color: rgba(222, 3, 127, 0);
    border-right-color: #890f3a;
    border-width: 7px;
    margin-top: -7px;
  }

  .timeline-left-side.date-circle:after {
    right: -10.5px;
  }

  .timeline-right-side {
    padding-left: 70px;
    text-align: left;
  }

  .timeline-right-side .timeline-arrow {
    left: 33px;
  }

  .timeline-right-side .timeline-arrow:after,
  .timeline-right-side .timeline-arrow:before {
    left: 100%;
  }

  .timeline-right-side .timeline-arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 6px;
    margin-top: -6px;
  }

  .timeline-right-side .timeline-arrow:before {
    border-color: rgba(222, 3, 127, 0);
    border-left-color: #890f3a;
    border-width: 7px;
    margin-top: -7px;
  }

  .timeline-right-side.date-circle:after {
    left: -10.5px;
  }
}

.timeline {
  padding-top: 10px;
  width: 100%;
  max-width: 1140px;
  margin: 10px auto;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.timeline:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  margin-right: -0.5px;
  width: 1px;
  background: #ccc;
}

.timeline-column {
  max-width: 100%;
  box-sizing: border-box;
}

.timeline-spacing {
  padding-bottom: 65px;
}

@media (min-width: 992px) {
  .timeline:after {
    right: 50%;
  }

  .timeline-column {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

@charset "UTF-8";

@font-face {
  font-family: 'wedding';
  src: url(/static/media/wedding.5eb3b560.eot);
  src: url(/static/media/wedding.5eb3b560.eot?#iefix) format('embedded-opentype'),
    url(/static/media/wedding.c2a37c16.woff) format('woff'),
    url(/static/media/wedding.f1f39c70.ttf) format('truetype'),
    url(/static/media/wedding.58c2f588.svg#wedding) format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'wedding' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'wedding' !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rings:before {
  content: 'a';
}
.icon-hearts:before {
  content: 'b';
}
.icon-wine-glasses:before {
  content: 'c';
}
.icon-bus:before {
  content: 'f';
}
.icon-angle-down:before {
  content: 'g';
}
.icon-angle-left:before {
  content: 'h';
}
.icon-angle-right:before {
  content: 'i';
}

.app {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: #000000;
}

.app-content {
  padding-bottom: 20px;
}

.d-none {
  display: none !important;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }
}

